var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"products-list-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"head-search ma-0 rounded",attrs:{"justify":"end","align":"center"}},[_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.mapWarehouse,"menu-props":{ offsetY: true },"item-value":"id","item-text":"name","label":"Warehouses","auto-select-first":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.warehouseSelected),callback:function ($$v) {_vm.warehouseSelected=$$v},expression:"warehouseSelected"}}),_c('v-btn',{staticClass:"mt-1 ml-2",attrs:{"color":"success","outlined":""},on:{"click":_vm.fetchData}},[_vm._v(" Search ")]),_c('v-btn',{staticClass:"mt-1 ml-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateCountStock'})}}},[_vm._v(" +Add ")])],1)],1),_c('div',{staticClass:"count-stock-table ma-0 mt-3 rounded"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.options.itemsPerPage,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"dense":"","footer-props":{'items-per-page-options': [15, 25, 50, 100]}},on:{"update:options":[function($event){_vm.options=$event},_vm.fetchData]},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeFormat")(item.createdAt))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.mapStateColor(item.state) })},[_vm._v(" "+_vm._s(_vm.mapStateText(item.state))+" ")])]}},{key:"item.sumStock",fn:function(ref){
var item = ref.item;
return [(item.state !== 'pending')?_c('span',{style:({ color: _vm.isCorrect(item.state, item.stockValue, item.countStockValue) })},[_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.sumStock))+" ("+_vm._s(_vm._f("showNumberFormat")(item.stockValue))+") ")]):_c('span',[_vm._v(" รอนับ ")])]}},{key:"item.countProduct",fn:function(ref){
var item = ref.item;
return [(item.state !== 'pending')?_c('span',{style:({ color: _vm.isCorrect(item.state, item.countProduct, item.sumStock) })},[_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.countProduct))+" ("+_vm._s(_vm._f("showNumberFormat")(item.countStockValue))+") ")]):_c('span',[_vm._v(" รอนับ ")])]}},{key:"item.prepareConfirmBy",fn:function(ref){
var item = ref.item;
return [(item.prepareConfirmDate && item.state === 'prepare_to_confirm')?_c('p',[_vm._v(" "+_vm._s(item.prepareConfirmBy.email)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("dateTimeFormat")(item.prepareConfirmDate))+" ")]):_vm._e()]}},{key:"item.approvedBy",fn:function(ref){
var item = ref.item;
return [(item.completedBy && item.state === 'completed')?_c('p',[_vm._v(" "+_vm._s(item.completedBy.email)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("dateTimeFormat")(item.completedDate))+" ")]):_vm._e()]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ name: 'StockCountDetail', params: { id: item.id }})}}},[_c('span',[_vm._v(" รายละเอียด ")])]),(item.state === 'pending' && _vm.checkRole)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.prepareConfrim(item)}}},[_c('span',[_vm._v(" นับของเสร็จ ")])]):_vm._e(),(item.state === 'completed' && _vm.checkRole)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.updatePending(item)}}},[_c('span',[_vm._v(" ย้อนสถานะ ")])]):_vm._e(),(item.state === 'prepare_to_confirm' && _vm.checkRole)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.updateConfirm(item)}}},[_c('span',[_vm._v(" ยืนยัน ")])]):_vm._e(),(item.state !== 'pending')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.dowloadCsv(item)}}},[_c('span',[_vm._v(" Export CSV ")])]):_vm._e(),(item.state === 'pending')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.detailCountStock(item)}}},[_c('span',[_vm._v(" นับของ ")])]):_vm._e()],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }