<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row
      justify="end"
      align="center"
      class="head-search ma-0 rounded">
      <v-col
        cols="4"
        class="d-flex align-center justify-end">
        <v-select
          v-model="warehouseSelected"
          :items="mapWarehouse"
          :menu-props="{ offsetY: true }"
          item-value="id"
          item-text="name"
          label="Warehouses"
          auto-select-first
          outlined
          hide-details
          dense />
        <v-btn
          color="success"
          outlined
          class="mt-1 ml-2"
          @click="fetchData">
          Search
        </v-btn>
        <v-btn
          color="success"
          class="mt-1 ml-2"
          @click="$router.push({ name: 'CreateCountStock'})">
          +Add
        </v-btn>
      </v-col>
    </v-row>

    <div class="count-stock-table ma-0 mt-3 rounded">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="options.itemsPerPage"
        :server-items-length="total"
        :options.sync="options"
        class="elevation-1"
        :loading="loading"
        dense
        :footer-props="{'items-per-page-options': [15, 25, 50, 100]}"
        @update:options="fetchData">
        <template #[`item.createdAt`]="{ item }">
          {{ item.createdAt | dateTimeFormat() }}
        </template>
        <template #[`item.state`]="{ item }">
          <span :style="{ color: mapStateColor(item.state) }">
            {{ mapStateText(item.state) }}
          </span>
        </template>
        <template #[`item.sumStock`]="{ item }">
          <span
            v-if="item.state !== 'pending'"
            :style="{ color: isCorrect(item.state, item.stockValue, item.countStockValue) }">
            {{ item.sumStock | showNumberFormat() }} ({{ item.stockValue | showNumberFormat() }})
          </span>
          <span v-else>
            รอนับ
          </span>
        </template>
        <template #[`item.countProduct`]="{ item }">
          <span
            v-if="item.state !== 'pending'"
            :style="{ color: isCorrect(item.state, item.countProduct, item.sumStock) }">
            {{ item.countProduct | showNumberFormat() }} ({{ item.countStockValue | showNumberFormat() }})
          </span>
          <span v-else>
            รอนับ
          </span>
        </template>
        <template #[`item.prepareConfirmBy`]="{ item }">
          <p v-if="item.prepareConfirmDate && item.state === 'prepare_to_confirm'">
            {{ item.prepareConfirmBy.email }} <br>
            {{ item.prepareConfirmDate | dateTimeFormat() }}
          </p>
        </template>
        <template #[`item.approvedBy`]="{ item }">
          <p v-if="item.completedBy && item.state === 'completed'">
            {{ item.completedBy.email }} <br>
            {{ item.completedDate | dateTimeFormat() }}
          </p>
        </template>
        <template #[`item.options`]="{ item }">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="$router.push({ name: 'StockCountDetail', params: { id: item.id }})">
                <span>
                  รายละเอียด
                </span>
              </v-list-item>
              <v-list-item
                v-if="item.state === 'pending' && checkRole"
                link
                @click="prepareConfrim(item)">
                <span>
                  นับของเสร็จ
                </span>
              </v-list-item>
              <v-list-item
                v-if="item.state === 'completed' && checkRole"
                link
                @click="updatePending(item)">
                <span>
                  ย้อนสถานะ
                </span>
              </v-list-item>
              <v-list-item
                v-if="item.state === 'prepare_to_confirm' && checkRole"
                link
                @click="updateConfirm(item)">
                <span>
                  ยืนยัน
                </span>
              </v-list-item>
              <v-list-item
                v-if="item.state !== 'pending'"
                link
                @click="dowloadCsv(item)">
                <span>
                  Export CSV
                </span>
              </v-list-item>
              <v-list-item
                v-if="item.state === 'pending'"
                link
                @click="detailCountStock(item)">
                <span>
                  นับของ
                </span>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import CountStockProvider from '@/resources/CountStockProvider'
import { mapGetters } from 'vuex'
import { getRole } from '@/assets/js/Authentication'
import { ExportToCsv } from 'export-to-csv'

const CountStockService = new CountStockProvider()

export default {
  data () {
    return {
      loading: false,
      ready: false,
      items: [],
      total: 0,
      warehouseSelected: null,
      headers: [
        {
          text: 'ID',
          value: 'id',
          align: 'center'
        },
        {
          text: 'Create Time',
          value: 'createdAt',
          align: 'center'
        },
        {
          text: 'Note',
          value: 'note',
          align: 'center',
          sortable: false
        },
        {
          text: 'State',
          value: 'state',
          align: 'center',
          sortable: false
        },
        {
          text: 'Warehouse',
          value: 'warehouse.name',
          align: 'center',
          sortable: false
        },
        {
          text: 'System Product (Value)',
          value: 'sumStock',
          align: 'center',
          sortable: false
        },
        {
          text: 'Count Product (value)',
          value: 'countProduct',
          align: 'center',
          sortable: false
        },
        {
          text: 'Completed By',
          value: 'prepareConfirmBy',
          align: 'center',
          sortable: false
        },
        {
          text: 'Approved By',
          value: 'approvedBy',
          align: 'center',
          sortable: false
        },
        {
          text: 'Options',
          value: 'options',
          align: 'center',
          sortable: false
        }
      ],
      states: {
        pending: {
          text: 'รอนับ',
          color: '#4caf50',
          value: 'pending'
        },
        prepare_to_confirm: {
          text: 'รอตรวจสอบ',
          color: '#fb9801',
          value: 'prepare_to_confirm'
        },
        completed: {
          text: 'ยืนยันแล้ว',
          color: '#7B1FA2',
          value: 'completed'
        },
        canceled: {
          text: 'ยกเลิก',
          color: '#616161',
          value: 'canceled'
        }
      },
      options: {
        page: 1,
        itemsPerPage: 15,
        sortDesc: [true],
        sortBy: ['createdAt']
      }
    }
  },
  computed: {
    ...mapGetters({
      warehouse: 'Store/mapWarehouse'
    }),
    checkRole () {
      const role = getRole()
      const settingRole = [
        'management',
        'area_manager',
        'developer',
        'support'
      ]

      return settingRole.some((r) => r === role)
    },
    mapWarehouse () {
      const role = getRole()
      const settingRole = [
        'management',
        'area_manager',
        'developer',
        'support'
      ]

      if (settingRole.some((r) => r === role)) {
        return this.warehouse
      }

      return this.warehouse.filter((w) => w.name !== 'Main')
    }
  },
  mounted () {
    this.warehouseSelected = this.mapWarehouse[0].id
    this.ready = true
    this.fetchData()
  },
  methods: {
    sumCount (data) {
      return data.products.reduce((ps, p) => ps + p.skus.reduce((ss, s) => s.count + ss, 0), 0) || 0
    },
    sumStock (data) {
      return data.products.reduce((ps, p) => ps + p.skus.reduce((ss, s) => s.stock + ss, 0), 0) || 0
    },
    sumCountValue (data) {
      return data.products.reduce((ps, p) => ps + p.skus.reduce((ss, s) => (s.count * s.price) + ss, 0), 0) || 0
    },
    sumStockValue (data) {
      return data.products.reduce((ps, p) => ps + p.skus.reduce((ss, s) => (s.stock * s.price) + ss, 0), 0) || 0
    },
    dowloadCsv (item) {
      const options = {
        filename: `${item.id}-${item.note}`,
        showLabels: false,
        useKeysAsHeaders: true
      }

      const data = []

      item.products.forEach((product) => {
        product.skus.forEach((sku) => {
          data.push({
            name: product.name,
            model: product.model,
            color: sku.color,
            size: sku.size,
            price: sku.price,
            stock: sku.stock,
            count: sku.count,
            sum: sku.count - sku.stock
          })
        })
      })

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    async fetchData () {
      if (!this.ready) return
      try {
        this.loading = true
        const { data } = await CountStockService.getAll({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          warehouse: this.warehouseSelected,
          sortBy: this.options.sortBy[0],
          sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc'
        })
        this.total = data.total
        this.items = data.results.map((item) => ({
          ...item,
          countProduct: this.sumCount(item),
          sumStock: this.sumStock(item),
          countStockValue: this.sumCountValue(item),
          stockValue: this.sumStockValue(item)
        }))
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch data]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    isCorrect (state, a, b) {
      if (state === 'pending') { return '#000000' }
      if (a !== b) { return '#e91e63' }
      return '#4caf50'
    },
    mapStateColor (state) {
      return this.states[state]?.color || '#000000'
    },
    mapStateText (state) {
      return this.states[state]?.text || state
    },
    async editCountStock (item) {
      this.$router.push({ name: 'EditCountStock', params: { id: item.id } })
    },
    async prepareConfrim (item) {
      try {
        this.loading = true
        await CountStockService.prepareConfrim(item.id)
        this.fetchData()
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[${error.code}] ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async updatePending (item) {
      try {
        this.loading = true
        await CountStockService.updatePending(item.id)
        this.fetchData()
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[${error.code}] ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async updateConfirm (item) {
      try {
        this.loading = true
        await CountStockService.completed(item.id)
        this.fetchData()
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[${error.code}] ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async detailCountStock (item) {
      this.$router.push({ name: 'CountStock', params: { id: item.id } })
    }
  }
}
</script>

<style scoped>
.count-stock-table {
  background-color: white;
  box-shadow: 1px 2px 5px 0px #c2c2c2;
  height: calc(100vh - 165px);
  overflow-y: auto;
}
</style>
