import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class CountStockProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  getAll (query) {
    this.setHeader(getAuthToken())
    return this.get('/count-stock', query)
  }

  getById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/count-stock/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/count-stock', payload)
  }

  updatePending (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/count-stock/${id}/pending`)
  }

  completed (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/count-stock/${id}/completed`)
  }

  prepareConfrim (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/count-stock/${id}/prepare-to-confirm`)
  }

  createCountStockDetail (payload) {
    this.setHeader(getAuthToken())
    return this.post('/count-stock/details', payload)
  }

  getCountStockDetails (payload) {
    this.setHeader(getAuthToken())
    return this.get('/count-stock/details/list', payload)
  }

  removeCountStockDetail (id) {
    this.setHeader(getAuthToken())
    return this.remove(`/count-stock/details?id=${id}`)
  }

  delete (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/count-stock?id=${id}`)
  }
}

export default CountStockProvider
